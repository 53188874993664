<template>
  <Header />
  <Timer @time="timeReached($event)" />
  <MainBanner />
  <div v-if="timeLeft !== null && timeLeft > 0" class="content ty-content" v-html="thankYouPageContent.trThankYouPageContent"></div>
  <div v-if="timeLeft !== null && timeLeft <= 0" class="content ty-content" v-html="thankYouPageContent.trThankYouPageContentAfter"></div>
  <!-- <EmailSubOverlay v-if="emailSubmissionOverlay.enableOverlay && showOverlay" v-on:close="showOverlay = false" :emailSubOverlayData="emailSubmissionOverlay" /> -->
</template>

<script>
import { mapGetters } from 'vuex';

import MainBanner from '@/components/MainBanner.vue';
// import EmailSubOverlay from '@/components/EmailSubOverlay.vue';
import Timer from '../components/Timer.vue';

export default {
  name: 'ThankYou',
  components: {
    MainBanner,
    // EmailSubOverlay,
    Timer,
  },
  data() {
    return {
      timeLeft: null,
      // showOverlay: true,
    };
  },
  methods: {
    timeReached(event) {
      this.timeLeft = event;
    },
  },
  computed: {
    ...mapGetters([
      'thankYouPageContent',
      // 'emailSubmissionOverlay',
    ]),
  },
  mounted() {
    // console.log(this.emailSubmissionOverlay);
  },
};
</script>

<style>
.ty-content {
  padding: 1rem;
  text-align: center;
  font-size: 14px;
  color: #4a4a4a;
}
</style>
